#root {
  height: 100vh;
}

.App-logo {
  height: 80px;
  padding: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex: 1;
  margin-top: 80px;
  text-align: center;
}

.ui.button.active,
.ui.basic.active.button {
  background-color: rgb(91, 201, 243) !important;
  color: white !important;
}

.ui.button.active:hover,
.ui.basic.active.button:hover {
  background-color: rgb(73, 179, 221) !important;
  color: white !important;
}

a {
  cursor: pointer;
}

.ui.github.button {
  background-color: #333;
  color: #fff;
}

i.bigger.icon,
i.bigger.icons {
  line-height: 1;
  vertical-align: middle;
  font-size: 3.5em;
}
