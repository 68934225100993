.ui.menu.MainNav {
  border-top: none;
  border-right: none;
  border-left: none;
  z-index: 3;
  width: 100%;
  border-radius: 0;
}

.app-logo {
  margin-left: 30px;
}

.Header-budgetContainer {
  font-size: 40px;
  line-height: 80px;
  color: rgb(134, 203, 250);
  display: flex;
  align-items: center;
  z-index: 3;
}

.Header-remainingBudget {
  color: rgb(153, 159, 173);
  font-size: 12px;
  width: 78px;
  display: inline-block;
  line-height: 15px;
  padding: 0 8px;
}

.Header-ButtonGroup {
  margin: 12px 0 0;
  display: flex;
  justify-content: flex-end;
}

.Header-ButtonGroup :first-child {
  margin-right: 12px;
}

.ui.menu.MainNav .menu .Header-userProfile .active .menu {
  left: -95px;
  top: 56px;
}
